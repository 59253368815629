.si-table-container {
  .si-header {
    .si-row {
      display: grid;
      background: #008a52;
      border: 1px solid #e1e7ff;
      box-sizing: border-box;
      height: 63px;
      color: white;
      align-items: center;
      padding: 0px 20px;
    }
  }
  .si-body {
    .si-row {
      display: grid;
      border: 1px solid #e1e7ff;
      box-sizing: border-box;
      height: 43px;
      align-items: center;
      padding: 0px 20px;
    }
  }
  .si-pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }
  .clickable {
    cursor: pointer;
    color: #008a52;
  }
}
