/** @format */

@import '../../styles/responsive.scss';
@import '../../styles/variables.scss';

.past-tickets-container {
  max-width: 1440px;
  margin: auto;
  padding: 0 60px;
  .create-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0px;
    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.1px;
      color: #373f41;
    }
    .l-c {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
  }
  .si-row {
    grid-template-columns: 0.6fr 1.5fr 1fr 0.8fr 1fr 1fr 1fr 1fr 0.5fr;
  }
  .clickable {
    cursor: pointer;
    color: #306fff;
  }
}
