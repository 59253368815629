@import "./responsive.scss";

@font-face {
  font-family: "ProximaNovaAltLight-Regular";
  src: url("../resources/fonts/ProximaNovaAltLight.otf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../resources/fonts/ProximaNovaFont.otf") format("truetype");
}

body {
  font-family: "ProximaNovaAltLight-Regular";
  letter-spacing: 0.5px;
  font-weight: bold;
  margin: 0px;
}
.error-msg {
  color: red;
  margin-top: 10px;
  display: inline-block;
}
.si-hide-mobile {
  @include respond-to("small") {
    display: none !important;
  }
}
.si-hide-web {
  display: none !important;
  @include respond-to("small") {
    display: inline-block !important;
  }
}

.s-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #373f41;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}