
@import "../styles/responsive.scss";
@import "../styles/variables.scss";

.main-container {  
  background-color: $bg-main;
  // max-width: 1440px;
  margin: auto;
  @include respond-to("small") {
    display: block;
    font-size: 14px;
  }
}
