/** @format */

.header-container {
  background: #ffffff;
  border: 1px solid #e1e7ff;
  box-sizing: border-box;
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  .left-c {
    display: flex;
    align-items: center;
  }
  .r-c {
    display: flex;
    align-items: center;
  }
}

.side-menu-header {
  display: flex;
  padding: 15px;
  justify-content: space-between;
}
